import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_API_URL);

const socketFunctions = {
    connectSocket: (user, setOnlineUsers) => {
        socket.emit("new-user-add", user?.userId);
        socket.on("get-users", (users) => {
            setOnlineUsers(users);
        });

        const handleFocus = () => {
            socket.emit("new-user-add", user?.userId);
        };

        const handleBlur = () => {
            if (user) {
                socket.emit("offline");
            }
        };

        window.addEventListener("focus", handleFocus);
        window.addEventListener("blur", handleBlur);

        return () => {
            window.removeEventListener("focus", handleFocus);
            window.removeEventListener("blur", handleBlur);
        };
    },

    sendMessage: (message) => {
        if (message) {
            socket.emit("send-message", message);
        }
    },

    receiveMessage: (setReceivedMessage, chatId) => {
        socket.on("recieve-message", (data) => {
            if (data.chatId === chatId) {
                setReceivedMessage(data);
            }
        });
    },

    messageSeen: (setMessages, messages) => {
        socket.on("message-seen", (data) => {
            messages[messages.length - 1].status = data.status;
            setMessages(messages);
        });
    },

    receiveUpload: (setReceivedMessage, chatId) => {
        socket.on("receive-upload", (data) => {
            if (data.chatId === chatId) {
                setReceivedMessage(data);
            }
        });
    },

    sendTyping: (user, chat, currentUser) => {
        const receiver = chat.members.find((id) => id !== currentUser);
        socket.emit("typing", {
            typer: user.firstname,
            receiverId: receiver,
        });
    },

    fetchMessages: async (currentUser, chat, setMessages, getMessages) => {
        try {
            if (chat) {
                const data = await getMessages(chat._id);
                setMessages(data);

                var lastMessage = data[data.length - 1];
                if (lastMessage?.senderId !== currentUser.userId) {
                    socket.emit("message-seen-status", {
                        chatId: chat._id,
                        userId: currentUser.userId,
                        status: "seen",
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    },

    fetchTyping: (setTyping) => {
        socket.on("get-typing", (data) => {
            setTyping(data.typer + " is typing..");

            setTimeout(() => {
                setTyping("");
            }, 2000);
        });
    },

    receiveMessageFromParent: (receivedMessage, chat, setMessages, messages) => {
        console.log("Message Arrived: ", receivedMessage);
        if (receivedMessage !== null && receivedMessage.chatId === chat._id) {
            setMessages([...messages, receivedMessage]);
            socket.emit("message-seen-status", receivedMessage);
        }
    },

    handleFileSelect: async (data) => {
        socket.emit("upload", data);
    },

    sendNotification: (data) => {
        if (data) {
            socket.emit("send-notification", data);
        }
    },

    receiveNotification: (setReceivedNotifications, userId) => {
        socket.on("recieve-notification", (data) => {
            if (data.receiverId === userId) {
                setReceivedNotifications(data);
            }
        });
    }
};

export default socketFunctions;