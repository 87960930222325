const routes = {
    home: '/',
    login: "/login",
    register: "/register",
    forgotPassword: "/forgot-password",
    changePassword: '/change-password',
    resetPassword: '/reset-password',
    verifyAccount: '/verify-account',
    importLinkedIn: '/import-linkedin',
    jobs: '/jobs',
    myJobs: '/my-jobs',
    jobDetails: '/jobs-details',
    companyProfile: '/company-profile',
    profile: '/profile',
    people: '/people',
    editProfile: '/edit-profile',
    myNetwork: '/my-network',
    messages: '/messages',
    notifications: '/notifications',
    faq: '/faq',
    terms: '/terms',
    privacy: '/privacy',
    blog: '/blog',
    blogDetails: '/blog-details',
    contact: '/contact',
    pricing: '/pricing',
    maintence: '/maintence',
    comingSoon: '/coming-soon',
    notFound: '/404'
};

export default routes;
