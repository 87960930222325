import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import Header from "./components/shared/header";
import Notification from "./components/shared/notification";
import { AppRoutes } from "./navigation/app-routes";
import { updateUser } from "./store/auth/actions";
import { getCookie } from "./utils/cookie-service";
import Loader from "./components/shared/loader";
import routes from "./constants/routes";
import { withRouter } from "./utils/withRouter";
import { ThemeContext } from "./context/ThemeContext";
import SendMessage from "./components/shared/send-message";

function App(props) {
  const { navigate, location, auth } = props;
  const { darkTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (auth.isAuthenticated === false) navigate(routes.login);
    else if (location.pathname.indexOf("login") > -1) navigate(routes.home);
  }, [auth.isAuthenticated]);

  const user = getCookie("bridge-me-user");
  if (user && !auth.currentUser) {
    const { updateUser } = props;
    updateUser(user);
  }

  return (
    <React.Fragment>
      {/* {darkTheme && <link href="css/style-dark.css" rel="stylesheet" />} */}
      <SendMessage />
      <Notification />
      <Loader />
      {auth.isAuthenticated && <Header />}
      <AppRoutes />
    </React.Fragment>
  );
}

export default connect((state) => ({ auth: state.auth }), { updateUser })(
  withRouter(App)
);
