import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { dateFormat, getFileType, getImageUrl, getSenderName, getSenderProfileImage } from "../../utils/utility";
import useChatHook from "../../hooks/useChatHook";
import { updateSelectedConnection } from '../../store/global/actions';
import socketFunctions from "../../utils/socket";
import useUploadHook from "../../hooks/useUploadHook";

export default function SendMessage(props) {
    const chatRef = useRef();
    const imageRef = useRef();
    const fileRef = useRef();
    const { findChat, createChat, getChatMessages, addChatMessage } = useChatHook();
    const { uploadFile } = useUploadHook();
    const [currentChat, setCurrentChat] = useState(null);
    const [chatMessages, setChatMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [receivedMessage, setReceivedMessage] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [error, setError] = useState(null);
    const { selectedConnection } = useSelector((state) => state.global);
    const { currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedConnection)
            findUserChat();
    }, [selectedConnection]);

    useEffect(() => {
        scrollToBottom();
    }, [chatMessages]);

    const findUserChat = async () => {
        const response = await findChat(currentUser.userId, selectedConnection.userId);
        if (response) {
            const chat = response[0];
            setCurrentChat(chat);
        }
    }

    useEffect(() => {
        if (currentChat)
            socketFunctions.fetchMessages(currentUser, currentChat, setChatMessages, getChatMessages);
    }, [currentChat]);

    useEffect(() => {
        socketFunctions.connectSocket(currentUser, setOnlineUsers);
    }, [currentUser]);

    useEffect(() => {
        if (currentChat) {
            socketFunctions.receiveMessage((data) => {
                setReceivedMessage(data);
            }, currentChat._id);
        }
    }, [currentChat]);

    useEffect(() => {
        socketFunctions.receiveMessageFromParent(
            receivedMessage,
            currentChat,
            setChatMessages,
            currentUser,
            chatMessages
        );
    }, [receivedMessage]);

    const onSendMessage = async () => {
        let response;
        if (!currentChat?._id) {
            response = await createChat({ senderId: currentUser.userId, receiverId: selectedConnection.userId, chatMessage: message });
            setCurrentChat(response);
        }

        const data = {
            chatMessage: message,
            chatId: currentChat._id,
            senderId: currentChat.senderId,
            sender: currentChat.sender,
            receiverId: currentChat.receiverId,
            receiver: currentChat.receiver,
            fromSender: currentUser.userId === currentChat.senderId,
            createdOn: moment.utc()
        };
        socketFunctions.sendMessage({ ...data, receiverId: data.senderId === currentUser.userId ? data.receiverId : data.senderId });
        setChatMessages([...chatMessages, data]);
        setCurrentChat({ ...currentChat, chatMessage: message });
        setMessage('');
        await addChatMessage({ ...data, chatMessage: message });
    }

    const handleFileSelect = async (event) => {
        setError('');
        const file = event.target.files[0];
        let type = getFileType(file.name);
        if (!type) {
            setError('File type not supported');
            return;
        }

        const filePath = await uploadFile({ media: file });
        const data = {
            filePath,
            type,
            fileName: file.name,
            chatId: currentChat._id,
            senderId: currentChat.senderId,
            sender: currentChat.sender,
            receiverId: currentChat.receiverId,
            receiver: currentChat.receiver,
            fromSender: currentUser.userId === currentChat.senderId,
            createdOn: moment.utc(),
        };

        setChatMessages([...chatMessages, data]);
        setCurrentChat({ ...currentChat, chatMessage: message });
        setMessage('');

        socketFunctions.handleFileSelect({ ...data, receiverId: data.senderId === currentUser.userId ? data.receiverId : data.senderId });
        await addChatMessage(data);
    };

    const scrollToBottom = () => {
        window.setTimeout(() => {
            chatRef.current?.scrollIntoView(false, { behavior: 'smooth' })
        }, 100);
    }

    const handleClose = () => {
        dispatch(updateSelectedConnection(null));
    }

    const getOnline = () => {
        return onlineUsers.find(m => m.userId === selectedConnection.userId);
    }

    return (
        <>
            {selectedConnection &&
                <div className="position-fixed small-chat bottom-0">
                    <div className="card rounded-4 overflow-hidden">
                        <div className="card-header">
                            <ul className="list-style-none p-0 m-0 d-flex justify-content-between">
                                <li className="d-flex align-items-center">
                                    <Avatar size="50" round name={`${selectedConnection?.firstName} ${selectedConnection?.lastName}`} src={getImageUrl(selectedConnection?.profileImage)}
                                        className="object-fit-cover rounded-pill me-3" />
                                    {getOnline() && <div className="online-dot bg-success"></div>}
                                    <div>
                                        <h6 className="mb-1 lh-base fw-600 text-dark text-opacity-75 text-capitalize">
                                            {selectedConnection?.firstName} {selectedConnection?.lastName}</h6>
                                        <p className="fs-14 lh-base text-secondary fw-500 text-capitalize mb-0">
                                            {selectedConnection.headline}
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <button type="button" className="border-0 bg-transparent d-flex p-1" onClick={handleClose}>
                                        <i className="fa fa-close fs-4 text-secondary"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body scroll osahan-chat-box p-3 bg-light" >
                            {chatMessages.length > 0 ? _.map(_.groupBy(chatMessages, item => dateFormat(item.createdOn, 'MM/DD/YYYY')), (keyItem, key) => (
                                <div key={key}>
                                    <div className="text-center my-3">
                                        <span className="px-3 py-2 small bg-white shadow-sm rounded">{dateFormat(key, 'MMM DD')}</span>
                                    </div>
                                    {
                                        _.map(keyItem, (item, i) => (
                                            <div key={i} className="d-flex align-items-center osahan-post-header" ref={chatRef}>
                                                <div className="dropdown-list-image me-3 mb-auto">
                                                    <Avatar size="40" round name={getSenderName(item)} src={getImageUrl(getSenderProfileImage(item))}
                                                        className="object-fit-cover rounded-pill border" />
                                                </div>
                                                <div className="me-1">
                                                    <div className="d-flex">
                                                        <div className="text-truncate chat-title h6 mb-1 me-2">
                                                            {getSenderName(item)}
                                                        </div>
                                                        <span>{dateFormat(item.createdOn, 'HH:mm')}</span>
                                                    </div>
                                                    <p>
                                                        {item.chatMessage && item.chatMessage}
                                                        {item.type === 'img' && <img src={getImageUrl(item.filePath)} alt='Received' className="chat-img" />}
                                                        {item.type === 'file' &&
                                                            <a href={getImageUrl(item.filePath)} target='_blank' rel="noreferrer">
                                                                <i className="feather-file"></i> {item.fileName}
                                                            </a>
                                                        }
                                                    </p>
                                                </div>
                                                {/* <span className="ms-auto mb-auto">
                                        <div className="text-right pt-1 small">{dateFormatDDMMYYYY(item.createdOn)}</div>
                                    </span> */}
                                            </div>))}
                                </div>
                            )) : <p className="text-center mb-0 lh-base text-secondary">Send message to start</p>}
                        </div>

                        <div className="bg-light p-3">
                            <textarea className="form-control rounded-3 p-3 shadow-none bg-secondary bg-opacity-10 border-0 text-dark mb-1 scroll" rows="2"
                                value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Enter your message here" />
                            <input type="file" className='d-none' ref={imageRef} onChange={handleFileSelect} accept="image/*" />
                            <input type="file" className='d-none' ref={fileRef} onChange={handleFileSelect} />
                            <div className="p-3 d-flex justify-items-center">
                                <div className="overflow-hidden">
                                    <button type="button" className="btn btn-light btn-sm rounded me-1" onClick={() => imageRef.current.click()}>
                                        <i className="feather-image"></i>
                                    </button>
                                    <button type="button" className="btn btn-light btn-sm rounded" onClick={() => fileRef.current.click()}>
                                        <i className="feather-paperclip"></i>
                                    </button>
                                    <span className='text-danger d-block'>{error}</span>
                                </div>
                                <span className="ms-auto">
                                    <button type="button" className="btn btn-primary btn-sm rounded" onClick={onSendMessage}>
                                        <i className="feather-send"></i> Send
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}