import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { post } from '../store/global/actions'
import { API_ACTIONS } from '../store/api-action'
import { getFormData } from '../utils/utility'

const useUploadHook = () => {
    const dispatch = useDispatch()

    const actions = useMemo(() => ({
        async uploadFile(file) {
            return dispatch(post(API_ACTIONS.upload, getFormData(file)));
        },
    }), [dispatch])

    return actions
}

export default useUploadHook;