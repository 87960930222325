import moment from "moment";
import { userImage } from "../constants/images";
import _ from "lodash";

export function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

export function getFromNowDateFormat(date) {
    return moment(date).fromNow();
}

export function getDateDiffFormat(date, endDate) {
    date = moment(date);
    endDate = moment(endDate || new Date());
    return moment.duration(endDate.diff(date)).humanize();
}

export function dateFormatYYYYMM(date) {
    return date ? moment(date).format(`YYYY-MM`) : '';
}

export function dateFormatMMYYYY(date) {
    return date ? moment(date).format(`MMM, YYYY`) : '';
}

export function dateFormatDDMMYYYY(date) {
    return date ? moment(date).format(`DD MMM YYYY HH:mm:ss`) : '';
}

export function dateFormat(date, format) {
    return date ? moment(date).format(format) : '';
}

export const copyToClip = (text) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    return true;
}

export const convertToSEO = (title) => {
    return title.trim().replaceAll(' ', '-').replaceAll('/', '-').replaceAll(',', '').toLocaleLowerCase();
}

export const getImageUrl = (fileName) => `${process.env.REACT_APP_IMAGE_URL}/${fileName}`;

export const getImageBase64Url = (file) => `data:image/jpeg;base64,${Buffer.from(file).toString("base64")}`;

export const getDefaultImage = ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = userImage;
}

export function isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url)
}

export function isVideo(url) {
    return /\.(mp4)$/.test(url)
}

export const getSenderName = (item) => {
    const sender = (item.fromSender ? item.sender : item.receiver);
    return `${sender?.firstName} ${sender?.lastName}`;
}

export const getSenderProfileImage = (item) => {
    const sender = (item.fromSender ? item.sender : item.receiver);
    return sender?.profileImage;
}

export const getFileType = (name) => {
    let type;
    if (name.match(/\.(jpg|jpeg|png)$/i))
        type = 'img';
    else if (name.match(/\.(pdf|docx|doc)$/i))
        type = 'file';

    return type;
}

export const getMonthList = () => {
    return moment.months();
}

export const getYearList = (startYear, endYear = moment().year()) => {
    return _.range(endYear, startYear);
}

export const getIndexedValue = (value, separator, index) => {
    return value ? value.split(separator)[index] : '';
}

export const getPadStart = (value, maxLength = 2, prefix = 0) => {
    return value.toString().padStart(maxLength, prefix)
}