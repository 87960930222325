import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { getAll, getById, post } from '../store/global/actions'
import { API_ACTIONS } from '../store/api-action'

const useChatHook = () => {
    const dispatch = useDispatch()

    const actions = useMemo(() => ({
        async findChat(senderId, receiverId) {
            return dispatch(getAll(API_ACTIONS.findChat, { senderId, receiverId }));
        },
        async createChat(data) {
            return dispatch(post(API_ACTIONS.chats, data));
        },
        async getChats() {
            return dispatch(getAll(API_ACTIONS.chats));
        },
        async getChat(chatId) {
            return dispatch(getById(API_ACTIONS.chats, chatId));
        },
        async getChatMessages(chatId) {
            return dispatch(getById(API_ACTIONS.messages, chatId));
        },
        async addChatMessage(data) {
            return dispatch(post(API_ACTIONS.messages, data, false, true));
        },
    }), [dispatch])

    return actions;
}

export default useChatHook;