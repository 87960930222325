export const API_ACTIONS = {
    login: 'login',
    register: 'register',
    sendOTP: 'send-otp',
    verifyOTP: 'verify-otp',
    verificationToken: 'verification-token',
    resetPassword: 'reset-password',
    changePassword: 'change-password',
    importLinkedIn: 'import-linkedin',
    profileImage: 'profile-image',
    profileInfo: 'profile-info',
    updateProfileInfo: 'update-profile',
    experiences: 'experiences',
    educations: 'educations',
    skills: 'skills',
    languages: 'languages',
    jobAction: {
        jobs: 'jobs',
        applied: 'jobs/applied',
        apply: 'jobs/apply',
        saved: 'jobs/saved',
        save: 'jobs/save',
        removeSaved: 'jobs/remove-saved'
    },
    projects: 'projects',
    invites: 'invites',
    inviteCounts: 'invites/count',
    myNetwork: 'my-network',
    search: 'search',
    feedList: 'feeds/list',
    feeds: 'feeds',
    feedLike: 'feeds/like',
    chats: 'chats',
    findChat: 'chats/find',
    messages: 'chats/messages',
    upload: 'upload',
    notifications: 'notifications',
    notificationCounts: 'notifications/count',
    interests: 'rss-feeds/mappings/interests'
}