import { DEFAULT_ACTION, LOG_OUT } from '../action-types';
import { HIDE_NOTIFICATION, UPDATE_NOTIFICATION, SHOW_LOADING, UPDATE_USER, UPDATE_USER_INFO, UPDATE_SELECTED_CONNECTION, UPDATE_INVITE_COUNT, UPDATE_NOTIFICATION_LIST } from '../mutations';
import { AuthenticationError, NetworkError, ClientError, ServerError } from '../../network/error-type';
import { NOTIFICATION_MESSAGES } from '../constants';
import { removeCookie } from '../../utils/cookie-service';
import httpService from "../../network/http-service";

export function post(action, payload, formData, hideLoader = false) {
    return async function (dispatch) {
        !hideLoader && dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.post({ action, data: payload, formData });
            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    variant: "success",
                    message: response.data.message
                }
            });
            return response?.data;
        } catch (err) {
            if (err.statusCode === 409) throw err;
            dispatch(handleError(DEFAULT_ACTION, err));
        } finally {
            !hideLoader && dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export function update(action, payload) {
    return async function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.put({ action, data: payload });
            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    variant: "success",
                    message: response.data.message
                }
            });
            return response?.data;
        } catch (err) {
            if (err.statusCode === 409) throw err;
            dispatch(handleError(DEFAULT_ACTION, err));
        } finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export function getAll(action, query, hideLoader) {
    return async function (dispatch) {
        !hideLoader && dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.get({ action, query });
            return response?.data;
        } catch (err) {
            if (err.statusCode === 409) throw err;
            dispatch(handleError(DEFAULT_ACTION, err));
        } finally {
            !hideLoader && dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export function getById(action, id) {
    return async function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.get({ action, segment: id });
            return response?.data;
        } catch (err) {
            if (err.statusCode === 409) throw err;
            dispatch(handleError(DEFAULT_ACTION, err));
        } finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export function deleteById(action, payload) {
    return async function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.delete({ action, data: payload });
            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    variant: "success",
                    message: response.data.message
                }
            });
            return response?.data;
        } catch (err) {
            if (err.statusCode === 409) throw err;
            dispatch(handleError(DEFAULT_ACTION, err));
        } finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export function showNotification() {
    return function (dispatch) {
        dispatch({ type: HIDE_NOTIFICATION });
    }
};

export function updateNotification(payload) {
    return function (dispatch) {
        dispatch({ type: UPDATE_NOTIFICATION, payload: payload });
    }
};

export function updateSpinner(payload) {
    return function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: payload });
    }
};

export function updateProfileInfo(payload) {
    return (dispatch) => {
        dispatch({ type: UPDATE_USER_INFO, payload: payload });
    };
};

export function updateSelectedConnection(payload) {
    return (dispatch) => {
        dispatch({ type: UPDATE_SELECTED_CONNECTION, payload: payload });
    };
};

export function updateNotificationList(payload) {
    return (dispatch) => {
        dispatch({ type: UPDATE_NOTIFICATION_LIST, payload: payload });
    };
};

export function updateInviteCount(payload) {
    return (dispatch) => {
        dispatch({ type: UPDATE_INVITE_COUNT, payload: payload });
    };
};

export function handleError(action, error) {
    return function (dispatch) {
        action = action || DEFAULT_ACTION;

        if (error instanceof ClientError) {
            const errorObj = NOTIFICATION_MESSAGES[action].error;
            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    title: errorObj.title,
                    variant: errorObj.variant,
                    message: error.message || errorObj.message
                }
            });
        } else if (error instanceof ServerError) {
            dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[action].error });
        } else if (error instanceof NetworkError) {
            dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[action].error });
        } else if (error instanceof AuthenticationError) {
            removeCookie('bridge-me-user');
            removeCookie('bridge-me-token');
            dispatch({ type: UPDATE_USER, payload: null });
            dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[LOG_OUT] });
        } else {
            const errorObj = NOTIFICATION_MESSAGES[action].error;
            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    title: errorObj.title,
                    variant: errorObj.variant,
                    message: error.message || errorObj.message
                }
            });
        }
    }
};