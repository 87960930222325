import { LOGIN, LOG_OUT } from '../action-types';
import { UPDATE_NOTIFICATION, SHOW_LOADING, UPDATE_USER } from "../mutations";
import { NOTIFICATION_MESSAGES } from "../constants";
import httpService from "../../network/http-service";
import { handleError } from "../global/actions";
import { removeCookie, setCookie } from "../../utils/cookie-service";
import { API_ACTIONS } from '../api-action';

export function login(payload) {
    return async function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.post({
                action: API_ACTIONS.login,
                data: payload
            });

            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    title: "Register",
                    variant: "success",
                    message: response.data.message
                }
            });
            return response?.data;
        } catch (err) {
            dispatch(handleError(LOGIN, err));
        } finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export function register(payload) {
    return async function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.post({
                action: API_ACTIONS.register,
                data: payload
            });

            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    title: "Register",
                    variant: "success",
                    message: response.data.message
                }
            });
            return response?.data;
        } catch (err) {
            dispatch(handleError(LOGIN, err));
        } finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export function sendOTP(payload) {
    return async function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.post({
                action: API_ACTIONS.sendOTP,
                data: payload
            });

            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    title: "Send OTP",
                    variant: "success",
                    message: response.data.message
                }
            });
            return response?.data;
        } catch (err) {
            dispatch(handleError(LOGIN, err));
        } finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export function verifyOTP(payload) {
    return async function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.post({
                action: API_ACTIONS.verifyOTP,
                data: payload
            });
            if (payload.register)
                dispatch({ type: UPDATE_USER, payload: response?.data?.user });

            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    title: "Verify OTP",
                    variant: "success",
                    message: response.data.message
                }
            });
            return response?.data;
        } catch (err) {
            if (err.statusCode) throw err;
            dispatch(handleError(LOGIN, err));
        } finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export function resetPassword(payload) {
    return async function (dispatch) {
        dispatch({ type: SHOW_LOADING, payload: true });
        try {
            const response = await httpService.post({
                action: API_ACTIONS.resetPassword,
                data: payload
            });

            dispatch({
                type: UPDATE_NOTIFICATION, payload: {
                    title: "Reset Password",
                    variant: "success",
                    message: response.data.message
                }
            });
            return response?.data;
        } catch (err) {
            dispatch(handleError(LOGIN, err));
        } finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
    };
}

export const logOut = () => {
    return (dispatch) => {
        removeCookie("bridge-me-user");
        removeCookie("bridge-me-token");
        dispatch({ type: UPDATE_USER, payload: null });
        dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[LOG_OUT] });
    };
};

export const updateUser = (payload) => {
    return (dispatch) => {
        setCookie("bridge-me-user", payload);
        dispatch({ type: UPDATE_USER, payload: payload });
    };
};