import React, { createContext, useState } from 'react';
import { getCookie, setCookie } from '../utils/cookie-service';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
    const [darkTheme, setDarkTheme] = useState(getCookie('bridge-me-dark-theme'));

    const toggleTheme = () => {
        setCookie('bridge-me-dark-theme', !darkTheme)
        setDarkTheme((prevTheme) => !prevTheme);
    };

    return (
        <ThemeContext.Provider value={{ darkTheme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeProvider, ThemeContext };