import { SHOW_LOADING, HIDE_NOTIFICATION, UPDATE_NOTIFICATION, UPDATE_USER_INFO, UPDATE_SELECTED_CONNECTION, UPDATE_NOTIFICATION_LIST, UPDATE_INVITE_COUNT } from '../mutations';

const initialState = {
    userInfo: {},
    spinner: 0,
    notification: null,
    showNotification: 0,
    selectedConnection: null,
    notificationList: [],
    inviteCount: 0
};

export default function globalReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                ...state,
                spinner: action.payload ? state.spinner + 1 : state.spinner - 1
            };
        case HIDE_NOTIFICATION:
            return {
                ...state,
                showNotification: 0
            };
        case UPDATE_NOTIFICATION:
            return {
                ...state,
                notification: action.payload,
                showNotification: state.showNotification + 1
            };
        case UPDATE_USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            };
        case UPDATE_SELECTED_CONNECTION:
            return {
                ...state,
                selectedConnection: action.payload
            };
        case UPDATE_NOTIFICATION_LIST:
            return {
                ...state,
                notificationList: action.payload
            };
        case UPDATE_INVITE_COUNT:
            return {
                ...state,
                inviteCount: action.payload
            };
        default:
            return state;
    }
}