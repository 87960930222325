import React, { createRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, NavDropdown, Navbar, Nav, Form, Dropdown, NavItem, NavLink } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import _ from "lodash";
import routes from "../../constants/routes";
import { withRouter } from "../../utils/withRouter";
import { logoPrimary } from "../../constants/images";
import { dateFormat, getDefaultImage, getImageUrl } from "../../utils/utility";
import useHeaderHook from "../../hooks/useHeaderHook";
import socketFunctions from "../../utils/socket";

const Header = (props) => {
  const searchRef = createRef();
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const { currentUser } = useSelector((state) => state.auth);
  const { userInfo, inviteCount, notificationList } = useSelector((state) => state.global);
  const {
    searchPeople,
    getInviteCount,
    getNotifications,
    getProfileInfo,
    logOutUser,
  } = useHeaderHook();
  const { navigate } = props;

  useEffect(() => {
    getCounts();
    fetchNotifications();
    getProfileInfo();
  }, []);

  useEffect(() => {
    socketFunctions.receiveNotification(() => {
      fetchNotifications();
      getCounts();
    }, currentUser.userId);
  }, []);

  const getCounts = async () => {
    await getInviteCount();
  };

  const fetchNotifications = async () => {
    await getNotifications();
  };

  const onLogOut = () => {
    const { navigate } = props;
    logOutUser();
    navigate(routes.login);
  };

  const onSearch = async (query) => {
    setIsLoading(true);
    const response = await searchPeople(query, true);
    setItems(response);
    setIsLoading(false);
  };

  const filterBy = () => true;

  return (
    <React.Fragment>
      <div className="app-header">
        <Container>
          <Navbar expand="lg">
            <Container fluid>
              <Link className="navbar-brand" to={routes.home}>
                <img src={logoPrimary} alt="Logo" />
              </Link>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Form className="search-bar">
                  <i className="bx bx-search icon"></i>
                  <AsyncTypeahead
                    ref={searchRef}
                    placeholder="Search people"
                    filterBy={filterBy}
                    id="search"
                    isLoading={isLoading}
                    labelKey={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    minLength={2}
                    onSearch={onSearch}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        searchRef.current.toggleMenu();
                        navigate(routes.people, {
                          state: { q: searchRef.current.state.text },
                        });
                      }
                    }}
                    maxResults={5}
                    paginate={true}
                    paginationText={"Show all"}
                    onPaginate={(e) => {
                      searchRef.current.toggleMenu();
                      navigate(routes.people, {
                        state: { q: searchRef.current.state.text },
                      });
                    }}
                    onChange={(selected) =>
                      selected &&
                      selected[0] &&
                      navigate(`${routes.profile}/${selected[0]._id}`)
                    }
                    options={items}
                    renderMenuItemChildren={(option) => (
                      <>
                        <img
                          alt={`${option.firstName} ${option.lastName}`}
                          src={getImageUrl(option.profileImage)}
                          onError={getDefaultImage}
                          className="list-image"
                        />
                        <span>{`${option.firstName} ${option.lastName}`}</span>
                      </>
                    )}
                  />
                </Form>
                <Nav as="ul" className="ms-auto my-2 my-lg-0" navbarScroll>
                  <Nav.Item as="li">
                    <Link className="nav-link" to={routes.jobs}>
                      <i className="bx bx-briefcase-alt-2"></i>
                      Jobs
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link" to={routes.myNetwork}>
                      <div className="icon">
                        <i className="feather-users"></i>
                        {inviteCount > 0 && (
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {inviteCount}
                          </span>
                        )}
                      </div>
                      My Network
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link" to={routes.messages}>
                      <i className="bx bx-envelope"></i>
                      Messaging
                    </Link>
                  </Nav.Item>
                  <NavDropdown
                    as="li"
                    align={"start"}
                    title={
                      <React.Fragment>
                        <div className="icon">
                          <i className="bx bx-bell"></i>
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {notificationList?.length}
                          </span>
                        </div>
                        Notifications
                      </React.Fragment>
                    }
                  >
                    <li>
                      <h6 className="dropdown-header">Alerts Center</h6>
                    </li>
                    <li>
                      {_.map(notificationList, (item, i) => (
                        <Link
                          className="dropdown-item d-flex justify-items-center"
                          to={routes.notifications}
                          key={i}
                        >
                          <div className="me-3">
                            <div className="icon-circle bg-primary">
                              <i className="feather-user text-white"></i>
                            </div>
                          </div>
                          <div>
                            <div className="small text-gray-500">
                              {dateFormat(item.createdOn, "MMMM DD, YYYY")}
                            </div>
                            <span className="font-weight-bold">
                              {item.title}
                            </span>
                          </div>
                        </Link>
                      ))}
                    </li>
                    <li>
                      <Link className="dropdown-item" to={routes.notifications}>
                        Show All Alerts
                      </Link>
                    </li>
                  </NavDropdown>
                  <Dropdown as={NavItem}>
                    <Dropdown.Toggle as={NavLink}>
                      <div className="user-profile">
                        <img
                          src={getImageUrl(userInfo.profileImage)}
                          onError={getDefaultImage}
                          alt="Profile"
                        />
                        {currentUser?.firstName} {currentUser?.lastName}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <li>
                        <Dropdown.Item className="justify-content-center">
                          <div className="user-profile">
                            <img
                              src={getImageUrl(userInfo.profileImage)}
                              onError={getDefaultImage}
                              alt="Profile"
                            />
                            <span>{currentUser?.firstName} {currentUser?.lastName}</span>
                            <span>{userInfo.headline}</span>
                          </div>
                        </Dropdown.Item>
                      </li>
                      <Dropdown.Divider />
                      <li>
                        <Link to={routes.profile} className="dropdown-item">
                          <i className="bx bx-user"></i> My Profile
                        </Link>
                      </li>
                      <Dropdown.Divider />
                      <li>
                        <Link to={routes.myJobs} className="dropdown-item">
                          <i className="bx bx-briefcase-alt-2"></i> My Jobs
                        </Link>
                      </li>
                      <Dropdown.Divider />
                      <li>
                        <Link
                          to={routes.changePassword}
                          className="dropdown-item"
                        >
                          <i className="bx bx-lock"></i> Change Password
                        </Link>
                      </li>
                      <Dropdown.Divider />
                      <li>
                        <Link onClick={onLogOut} className="dropdown-item">
                          <i className="bx bx-log-in"></i> Logout
                        </Link>
                      </li>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <Nav.Item as="li">
                    <ThemeToggle />
                  </Nav.Item> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Header);
