import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { getAll, updateInviteCount, updateNotificationList, updateProfileInfo } from '../store/global/actions'
import { logOut } from '../store/auth/actions'
import { API_ACTIONS } from '../store/api-action'

const useHeaderHook = () => {
    const dispatch = useDispatch()

    const actions = useMemo(() => ({
        async getNotifications() {
            const response = await dispatch(getAll(API_ACTIONS.notifications));
            dispatch(updateNotificationList(response));
        },
        async getInviteCount() {
            const response = await dispatch(getAll(API_ACTIONS.inviteCounts));
            dispatch(updateInviteCount(response));
        },
        async searchPeople(query, hideLoader) {
            return dispatch(getAll(API_ACTIONS.search, { q: query }, hideLoader));
        },
        async getProfileInfo() {
            const response = await dispatch(getAll(API_ACTIONS.profileInfo));
            dispatch(updateProfileInfo(response));
        },
        logOutUser() {
            dispatch(logOut());
        }
    }), [dispatch])

    return actions
}

export default useHeaderHook;