import { useRoutes } from 'react-router-dom';
import { lazyLoadRoutes } from '../utils/lazy-loader';
import routes from '../constants/routes';

export function AppRoutes() {
    const appRoutes = [
        {
            path: '/',
            children: [
                {
                    index: true,
                    element: lazyLoadRoutes('feeds'),
                },
                {
                    path: routes.login,
                    element: lazyLoadRoutes('auth/login', false),
                },
                {
                    path: routes.register,
                    element: lazyLoadRoutes('auth/register', false),
                },
                {
                    path: routes.forgotPassword,
                    element: lazyLoadRoutes('auth/forgot-password', false),
                },
                {
                    path: routes.resetPassword,
                    element: lazyLoadRoutes('auth/reset-password', false),
                },
                {
                    path: routes.verifyAccount,
                    element: lazyLoadRoutes('auth/verify-account', false),
                },
                {
                    path: routes.importLinkedIn,
                    element: lazyLoadRoutes('profile/import-linkedin'),
                },
                {
                    path: routes.changePassword,
                    element: lazyLoadRoutes('profile/change-password'),
                },
                {
                    path: routes.blog,
                    element: lazyLoadRoutes('blogs/index'),
                },
                {
                    path: routes.blogDetails,
                    element: lazyLoadRoutes('blogs/details'),
                },
                {
                    path: routes.jobs,
                    element: lazyLoadRoutes('jobs/index'),
                },
                {
                    path: routes.myJobs,
                    element: lazyLoadRoutes('jobs/my-jobs'),
                },
                {
                    path: `${routes.jobDetails}/:id`,
                    element: lazyLoadRoutes('jobs/details'),
                },
                {
                    path: routes.companyProfile,
                    element: lazyLoadRoutes('company/profile'),
                },
                {
                    path: routes.profile,
                    element: lazyLoadRoutes('profile/index'),
                },
                {
                    path: `${routes.profile}/:id`,
                    element: lazyLoadRoutes('profile/view'),
                },
                {
                    path: routes.people,
                    element: lazyLoadRoutes('people/list'),
                },
                {
                    path: routes.myNetwork,
                    element: lazyLoadRoutes('profile/my-network'),
                },
                {
                    path: routes.messages,
                    element: lazyLoadRoutes('profile/messages'),
                },
                {
                    path: routes.notifications,
                    element: lazyLoadRoutes('profile/notifications'),
                },
                {
                    path: routes.comingSoon,
                    element: lazyLoadRoutes('coming-soon', false),
                },
                {
                    path: routes.contact,
                    element: lazyLoadRoutes('contact'),
                },
                {
                    path: routes.maintence,
                    element: lazyLoadRoutes('maintence', false),
                },
                {
                    path: routes.pricing,
                    element: lazyLoadRoutes('pricing'),
                },
                {
                    path: routes.privacy,
                    element: lazyLoadRoutes('privacy', false),
                },
                {
                    path: routes.terms,
                    element: lazyLoadRoutes('terms', false),
                },
                {
                    path: routes.faq,
                    element: lazyLoadRoutes('faq', false),
                },
                {
                    path: routes.notFound,
                    element: lazyLoadRoutes('not-found', false),
                }
            ]
        },
        {
            path: '*',
            element: lazyLoadRoutes('not-found')
        }
    ];

    return useRoutes(appRoutes);
}